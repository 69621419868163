import { useStaticQuery, graphql } from "gatsby"

export const useFooterData = () => {
    const data = useStaticQuery(
        graphql`
query {
  allContentYaml {
    nodes {
      footer {
        categories {
          coaching {
            href
            name
          }
          company {
            href
            name
          }
          legal {
            href
            name
          }
        }
      }
    }
  }
}
`)
    return data.allContentYaml.nodes.length ? data.allContentYaml.nodes.filter(elem => elem.footer !== null)[0] : {}
}
