import * as React from "react"
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
  MenuIcon,
  XIcon,
} from '@heroicons/react/outline'
import { Link } from "gatsby"
import LogoMarkDark from '../images/logo-mark-teal-500-cyan-600.svg'
import { useNavbarData } from "../hooks/use-navbar-data"

const NavbarLight = () => {
    const data = useNavbarData()

    return (
      <Popover as="header" className="relative">
        <div className="bg-gray-50">
          <nav
            className="relative max-w-7xl mx-auto flex items-center justify-between pt-6 px-6 xl:px-8"
            aria-label="Global"
          >
            <div className="flex items-center flex-1">
              <div className="flex items-center justify-between w-full lg:w-auto">
                <Link to="/">
                  <span className="sr-only">Horse Of Course</span>
                  <LogoMarkDark className="h-8 w-auto sm:h-10" />
                </Link>
                <div className="-mr-2 flex items-center lg:hidden">
                  <Popover.Button className="bg-gray-50 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2 focus-ring-inset focus:ring-teal-500">
                    <span className="sr-only">Open main menu</span>
                    <MenuIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
            </div>
            <div className="hidden lg:flex lg:items-center lg:space-x-6">
              <Link to={data.navbar.login_link.href} className="text-base font-medium text-gray-500 hover:text-gray-900">
                {data.navbar.login_link.name}
              </Link>
              <Link
                to={data.navbar.waitlist_link.href}
                className="py-2 px-6 border border-transparent rounded-md text-base font-medium text-cyan-700 bg-cyan-100 hover:bg-cyan-200"
              >
                {data.navbar.waitlist_link.name}
              </Link>
            </div>
          </nav>
        </div>

        <Transition
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel focus className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top lg:hidden">
            <div className="rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="px-5 pt-4 flex items-center justify-between">
                <div>
                  <LogoMarkDark className="h-8 w-auto" />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-teal-500">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="pt-5 pb-6">
                <div className="mt-6 px-5">
                  <Link
                    to={data.navbar.waitlist_link.href}
                    className="block text-center w-full py-2 px-4 border border-transparent rounded-md shadow text-cyan-700 bg-cyan-100 font-medium hover:bg-cyan-200"
                  >
                    {data.navbar.waitlist_link.name}
                  </Link>
                </div>
                <div className="mt-6 px-5">
                  <p className="text-center text-base font-medium text-gray-500">
                    Already a user?{' '}
                    <Link to={data.navbar.login_link.href} className="text-gray-900 hover:underline">{data.navbar.login_link.name}</Link>
                  </p>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    )
}

export default NavbarLight
