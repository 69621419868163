import { useStaticQuery, graphql } from "gatsby"

export const useNavbarData = () => {
    const data = useStaticQuery(
        graphql`
query {
  allContentYaml {
    nodes {
      navbar {
        left_links {
          href
          name
        }
        login_link {
          href
          name
        }
        waitlist_link {
          href
          name
        }
      }
    }
  }
}
`)
    return data.allContentYaml.nodes.length ? data.allContentYaml.nodes.filter(elem => elem.navbar !== null)[0] : {}
}
