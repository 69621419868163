import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { ChevronRightIcon, StarIcon } from '@heroicons/react/solid'
import Layout from '../components/layout'
import LogoMarkDark from '../images/logo-mark-teal-500-cyan-600.svg'

const JoinWaitlistPage = () => (
    <div className="bg-white">
      <main>
        {/* Hero section */}
        <div className="pt-8 overflow-hidden sm:pt-12 lg:relative lg:py-48">
          <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
            <div>
              <div>
                <Link to="/">
                  <LogoMarkDark className="h-11 w-auto"/>
                </Link>
              </div>
              <div className="mt-20">
                <div>
                  <div className="inline-flex space-x-4">
                    <span className="rounded bg-cyan-50 px-2.5 py-1 text-xs font-semibold text-cyan-500 tracking-wide uppercase">
                      What&apos;s new
                    </span>
                    <span className="inline-flex items-center text-sm font-medium text-cyan-500 space-x-1">
                      <span>Beta invites are coming!</span>
                    </span>
                  </div>
                </div>
                <div className="mt-6 sm:max-w-xl">
                  <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                    Horsing around shouldn&apos;t feel like work.
                  </h1>
                  <p className="mt-6 text-xl text-gray-500">
                    Take a load off. Let us help manage your busy horse life.
                  </p>
                </div>
                <form action="https://api-mkt.horseofcourseapp.com/join-waitlist" method="POST" className="mt-12 sm:max-w-lg sm:w-full sm:flex">
                  <div className="min-w-0 flex-1">
                    <label htmlFor="hero-email" className="sr-only">
                      Email address
                    </label>
                    <input
                      id="hero-email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="block w-full border border-gray-300 rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:border-cyan-500 focus:ring-cyan-500"
                      placeholder="Enter your email"
                    />
                  </div>
                  <div className="mt-4 sm:mt-0 sm:ml-3">
                    <button
                      type="submit"
                      className="block w-full rounded-md border border-transparent px-5 py-3 bg-cyan-500 text-base font-medium text-white shadow hover:bg-cyan-600 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 sm:px-10"
                    >
                      Join the waitlist
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="sm:mx-auto sm:max-w-3xl sm:px-6">
          <div className="py-12 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            <div className="hidden sm:block">
              <div className="absolute inset-y-0 left-1/2 w-screen bg-gray-50 rounded-l-3xl lg:left-80 lg:right-0 lg:w-full" />
              <svg
                className="absolute top-8 right-1/2 -mr-3 lg:m-0 lg:left-0"
                width={404}
                height={392}
                fill="none"
                viewBox="0 0 404 392"
              >
                <defs>
                  <pattern
                    id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={404} height={392} fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)" />
              </svg>
            </div>
            <div className="relative pl-4 -mr-40 sm:mx-auto sm:max-w-3xl sm:px-0 lg:max-w-none lg:h-full lg:pl-12">
              <StaticImage
                src="../images/trainer-page-1024.png"
                quality={100}
                width={1024}
                height={725}
                formats={["auto","webp"]}
                className="w-full rounded-md shadow-xl ring-1 ring-black ring-opacity-5"
              />
            </div>
          </div>
        </div>
      </main>
    </div>
)

export default JoinWaitlistPage
